import { v4 } from 'uuid';
import { saveAs } from 'file-saver';
import { passwordIsValid } from './validators';
import { Classroom } from './types/classroom';

type AvatarImageProps = {
  avatar?: string | null;
  icon?: number | null;
  courseOrder: number;
};

export function groupBy<T, K>(value: Array<T>, reducer: (a: T) => K) {
  return value.reduce((prevVal: any, currVal: any) => {
    const k: K = reducer(currVal);
    (prevVal[k] || (prevVal[k] = [])).push(currVal);
    return prevVal;
  }, {});
}

export const sortBy = (key: string) => {
  return (a: any, b: any) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

export const isEqual = (first: any, second: any) => {
  if (first === second) return true;
  if ((!first || !second) && (first || second)) return false;
  const typeFirst = first?.constructor.name;
  const typeSecond = second?.constructor.name;
  if (typeFirst !== typeSecond) return false;

  if (typeFirst === 'Array') {
    if (first.length !== second.length) return false;
    for (let i = 0; i < first.length; ++i) {
      if (!isEqual(first[i], second[i])) return false;
    }
    return true;
  }

  if (typeFirst === 'Object') {
    const keysFirst = Object.keys(first);
    const keysSecond = Object.keys(second);
    if (!isEqual(keysFirst, keysSecond)) return false;
    for (const key in keysFirst) {
      if (!isEqual(first[key], second[key])) return false;
    }
    return true;
  }

  return false;
};

export const isEmpty = (value: any): boolean => {
  if (typeof value === 'string') return value.length === 0;
  if (value === undefined || value === null) return true;
  const type = value.constructor.name;
  if (type === 'Array') return value.length === 0;
  if (type === 'Object') return Object.keys(value).length === 0;

  return false;
};

export const uniqBy = (arr: Array<any>, key: string) => {
  return [
    ...arr
      .reduce((map, item) => {
        const keyMap = item[key];
        map.has(keyMap) || map.set(keyMap, item);
        return map;
      }, new Map())
      .values(),
  ];
};

export function throttle(func: any, timeFrame: number) {
  let lastTime = 0;
  return function (...args: any) {
    const now = new Date().getTime();
    if (now - lastTime >= timeFrame) {
      func(...args);
      lastTime = now;
    }
  };
}

export function cloneDeep<T>(object: T): T {
  if (typeof structuredClone === 'function') {
    return structuredClone(object);
  }

  return JSON.parse(JSON.stringify(object)) as T;
}

export const getRandomItem = <T>(array: T[]): T =>
  array[Math.floor(Math.random() * array.length)];

export function newId(): string {
  return v4();
}

export const getRandomNumber = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1) + min);

export function saveFile(
  fileURL: string | undefined,
  fileName: string | undefined,
  detectIsInWebview: () => boolean
): void {
  if (!fileURL || !fileName) return;

  // Send the url to webview to open the file
  if (detectIsInWebview()) {
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = fileName;
    link.click();
  } else {
    fetch(fileURL, {
      method: 'GET',
      headers: {},
      mode: 'cors',
      credentials: 'same-origin',
      // responseType: 'blob',
      redirect: 'follow',
    })
      .then((response) => response.blob())
      .then((blob) => saveAs(blob, fileName))
      .catch(() => {
        console.log('BAD FILE', fileName);
      });
  }
}

export function listToMatrix<T>(list: T[], elementsPerSubArray: number) {
  const matrix: T[][] = [];
  let i;
  let k;
  for (i = 0, k = -1; i < list.length; i++) {
    if (i % elementsPerSubArray === 0) {
      k++;
      matrix[k] = [];
    }
    matrix[k].push(list[i]);
  }

  return matrix;
}

export function decodeStringWithAscii(s: string) {
  const el = document.createElement('div');
  el.innerHTML = s;
  return el.innerText;
}

export const convertObjectToQueryParams = (obj: { [key: string]: any }) => {
  if (Object.keys(obj).length === 0) return '';
  return Object.keys(obj).reduce((a, c, i) => {
    const newFilter = obj[c];
    if (!newFilter) return a;
    if (i === 0) return `${c}=${newFilter}`;
    return `${a}&${c}=${newFilter}`;
  }, '');
};

export const depthFirstSearchForAssetItemsInDigitalGuides = (
  images: any,
  node: any
) => {
  if (!node || typeof node !== 'object') return;
  const keys = Object.keys(node);
  if (keys.includes('label') && node.label === 'Asset') {
    images.push(node.items);
  } else {
    for (let i = 0; i < keys.length; i++) {
      depthFirstSearchForAssetItemsInDigitalGuides(images, node[keys[i]]);
    }
  }
};

export const addParamToUrl = (param: string, value: string) => {
  const url = new URL(window.location.href);
  url.searchParams.set(param, value);
  window.history.replaceState({}, '', url);
};

export const addParamToUrlAndRefresh = (param: string, value: string) => {
  addParamToUrl(param, value);
  window.location.reload();
};

export const encodeBase64 = (str: string) => {
  return btoa(str);
};

export const decodeBase64 = (str: string) => {
  return atob(str);
};

export const stripHTMLTags = (string: string | undefined) =>
  string?.replace('\n', '').replace(/(<([^>]+)>)/gi, '');

export const truncateWords = (words: string, size: number): string => {
  if (!words) return '';
  const caracters = words.replace(/\s/g, '').length;
  return caracters > size ? words.slice(0, size + 1) + '…' : words;
};

export const generateRandomString = () => {
  let randomPassword = '';
  while (!passwordIsValid(randomPassword))
    randomPassword = Math.random().toString(36).slice(-8);
  return randomPassword;
};

export function extractPlaybackId(url: string) {
  const regex = /https:\/\/stream\.mux\.com\/([a-zA-Z0-9]+)/;
  const match = url.match(regex);

  return match ? match[1] : null;
}

export function sortClassrooms(classrooms?: Classroom[]) {
  return classrooms
    ?.sort((a, b) => {
      if (!a.name || !b.name) return 0;

      const nameA = a.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

      const nameB = b.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

      if (nameA?.toLowerCase() < nameB?.toLowerCase()) return -1;
      else return 1;
    })
    .sort((a, b) => {
      if (!a.courseOrder || !b.courseOrder) return 0;
      return a.courseOrder - b.courseOrder;
    });
}
